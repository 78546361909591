.tableInfoHeader tr td,
.tableInfoHeader tr th {
  border: none;
}
.css-1c2m6kz {
  border-bottom: 1px solid;
} 
.css-zp2dtr,.css-1c2m6kz,header  {
  background-color: #1890ff !important;
  color:white
}

.css-1yl4b1w-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: #1890ff;
  color:white;
  font-weight: bold;
}
.css-1ei61u3-MuiTypography-root {
  color:white !important
}
.css-1sx7tm3-MuiTypography-root,.anticon {
  color:white !important
}
.css-o2d1ik-MuiTypography-root {
  color:white !important;
  font-weight: bold;
}
button[aria-label="open drawer"] {
  background-color: red;
}
.css-1yl4b1w-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: mediumblue;
}